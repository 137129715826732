import { Layout, Space } from 'antd';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { maxMediaQuery } from '../../../shared/constants/media-queries';
import { useBreakpoints } from '../../../shared/hooks/use-breakpoints';
import { Feature } from '../../../shared/models/features';
import { Breakpoint } from '../../../shared/models/breakpoints';
import { getNavigationItemsFromRoutes } from '../../utils/routes';
import { Breadcrumbs } from '../breadcrumbs';
import { HeaderLogo, UserActionsButton } from '../header-content';
import { SideNavigation } from '../navigation';
import { LanguageSelector } from '../language-selector';
import { appRoutes } from '../../routes/app-routes';
import { coreRoutes } from '../../routes/core-routes';
import { colors, border, fontSize } from '../../../shared/constants/styles';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { useConfig } from '../../../shared/hooks/use-config';

const { Header, Sider, Content } = Layout;

export const SideNavigationLayout: FC = ({ children }) => {
  const theme = useTheme();
  const { NODE_ENV } = useConfig();
  const { lg } = useBreakpoints();
  const { pathname: currentPage } = useLocation();
  const permissions = useSelector(permissionSelector);

  const navigationRoutes = useMemo(() => getNavigationItemsFromRoutes(appRoutes, permissions), [permissions]);
  const backgroundColor =
    NODE_ENV === 'development' ? colors.ping : NODE_ENV === 'production' ? colors.primary : colors.turquoise;

  return (
    <Layout>
      <StyledHeader style={{ background: backgroundColor }}>
        <HeaderLogo />
        <Space size={0}>
          <UserActionsButton env={NODE_ENV} />
          <LanguageSelector env={NODE_ENV} />
        </Space>
      </StyledHeader>
      <MainArea>
        <StyledSider
          breakpoint={Breakpoint.XL}
          collapsible={lg}
          defaultCollapsed={lg}
          width={theme[Feature.CORE].layouts.sideNavigation.sider.width}
        >
          <SideNavigation navigationRoutes={navigationRoutes} />
          <VersionContainer>1.2.6</VersionContainer>
        </StyledSider>
        <InnerArea>
          {currentPage !== coreRoutes.path && <Breadcrumbs />}
          <StyledContent style={{ borderTop: currentPage !== coreRoutes.path ? border('darkGrey') : 'none' }}>
            {children}
          </StyledContent>
        </InnerArea>
      </MainArea>
    </Layout>
  );
};

const StyledHeader = styled(Header)`
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: ${props => props.theme[Feature.CORE].layouts.sideNavigation.header.height}px;
  line-height: ${props => props.theme[Feature.CORE].layouts.sideNavigation.header.height}px;
  padding: 0 24px;
  color: ${props => props.theme[Feature.CORE].layouts.sideNavigation.header.color};
`;

const MainArea = styled(Layout)`
  margin-top: ${props => props.theme[Feature.CORE].layouts.sideNavigation.header.height}px;
`;

const StyledSider = styled(Sider)`
  position: fixed !important;
  z-index: 1;
  overflow: auto;
  top: ${props => props.theme[Feature.CORE].layouts.sideNavigation.header.height}px;
  bottom: 0;
  left: 0;
  overflow-x: hidden;

  ul {
    color: ${colors.primary};
    border-right: ${border('primary')} !important;

    li {
      background: ${colors.white};

      ul.ant-menu.ant-menu-sub.ant-menu-inline {
        border-right: none !important;
        border-left: ${border('primary', 8)};
        border-top: ${border('primary')};
        border-bottom: ${border('primary')};

        li {
          padding-left: 16px !important;
        }
      }

      .ant-menu-title-content {
        color: ${colors.primary};
      }
    }

    .ant-menu-item,
    li .ant-menu-submenu-title {
      padding-left: 16px !important;
      width: 100%;
      height: 45px !important;
      margin-block: 0;
      margin-inline: unset;
      border-radius: 0px;
      border-bottom: ${border('primary')};
    }

    .ant-menu-submenu.ant-menu-submenu-inline {
      padding-left: 0px !important;

      i {
        color: ${colors.primary};
      }
    }

    .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title {
      border-bottom: ${border('primary')};
    }
  }
`;

const InnerArea = styled(Layout)`
  margin-left: ${props => props.theme[Feature.CORE].layouts.sideNavigation.sider.width}px;
  min-height: calc(100vh - ${props => props.theme[Feature.CORE].layouts.sideNavigation.header.height}px);
  padding: 0 24px 24px;
  background: ${colors.white};

  ${maxMediaQuery[Breakpoint.LG]} {
    margin-left: 80px;
  }
`;

const StyledContent = styled(Content)`
  z-index: 0;
  padding-top: 16px;
  flex: none;
  overflow: initial;
  margin-bottom: 50px;
`;

const VersionContainer = styled.div`
  height: 20px;
  text-align: center;
  border-inline-end: ${border('primary')};
  background-color: ${colors.white};
  color: ${colors.primary};
  font-size: ${fontSize.ONE};
`;
